import React from 'react'
import { Modal, Result, Typography } from 'antd'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import DOMPurify from 'dompurify'

interface INotificationModalProps {
  setIsVisible: Function
  onClose?: Function
  isVisible: boolean
  title?: string
  message?: string
  isLoading?: boolean
  errorMessage?: string
}

export default function NotificationModal ({ isVisible, title, setIsVisible, message, isLoading, errorMessage, onClose }: INotificationModalProps) {
  const handleModalClose = () => {
    setIsVisible(false)
    if (onClose) onClose()
  }

  const finalTitle = isLoading ? 'Carregando...' : title

  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/
  const CONFIG_ALL_YOUTUBE_HTML = {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allowfullscreen', 'src'],
    ADD_HOOK: (node:any) => {
      if (node.nodeName === 'IFRAME' && node.hasAttribute('src')) {
        const src = node.getAttribute('src')
        if (!youtubeRegex.test(src)) {
          node.remove()
        }
      }
    }
  }

  return (
    <Modal
      visible={isVisible}
      onCancel={handleModalClose}
      centered
      destroyOnClose
      footer={null}
      maskStyle={{ zIndex: 1051 }}
      wrapProps={{
        style: { zIndex: 1052 }
      }}
    >
      <Typography.Title level={5}>{finalTitle}</Typography.Title>

      {isLoading ? (
        <LoadingSpinner />
      ) : errorMessage ? (
        <Result status='error' title='Algo deu errado' subTitle={errorMessage} />
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message || '', CONFIG_ALL_YOUTUBE_HTML) }}
        />
      )}
    </Modal>
  )
}
